"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Router = exports.NATIVE_ADDRESS = void 0;
const amm_1 = require("@zenlink-interface/amm");
const currency_1 = require("@zenlink-interface/currency");
const util_1 = require("../util");
const MultiRouter_1 = require("./MultiRouter");
const RouteProcessor_1 = require("./RouteProcessor");
const RouteProcessor2_1 = require("./RouteProcessor2");
const AggregationRouter_1 = require("./AggregationRouter");
exports.NATIVE_ADDRESS = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE';
function TokenToBaseToken(t) {
    if (t instanceof currency_1.Token)
        return t;
    const nativeBaseToken = {
        address: '',
        name: t.name,
        symbol: t.symbol,
        chainId: t.chainId,
    };
    return nativeBaseToken;
}
class Router {
    constructor(dataFetcher, fromToken, amountIn, toToken, gasPrice, providers, // all providers if undefined
    poolFilter, minUpdateDelay = 1000) {
        this.dataFetcherPreviousState = 0;
        this.dataFetcher = dataFetcher;
        this.fromToken = fromToken;
        this.amountIn = amountIn;
        this.toToken = toToken;
        this.gasPrice = gasPrice;
        this.providers = providers;
        this.minUpdateDelay = minUpdateDelay;
        this.poolFilter = poolFilter;
    }
    startRouting(p) {
        this.stopRouting();
        this.routeCallBack = p;
        this.currentBestRoute = undefined;
        this.dataFetcherPreviousState = 0;
        this._checkRouteUpdate(); // Maybe a route is ready
        this.timer = setInterval(() => this._checkRouteUpdate(), this.minUpdateDelay);
    }
    // To stop gather pool data and routing calculation
    stopRouting() {
        if (this.timer)
            clearInterval(this.timer);
        this.timer = undefined;
    }
    getBestRoute() {
        return this.currentBestRoute;
    }
    _checkRouteUpdate() {
        const currentDataFetcherStateId = this.dataFetcher.getCurrentPoolStateId(this.providers);
        if (this.dataFetcherPreviousState !== currentDataFetcherStateId) {
            this.dataFetcherPreviousState = currentDataFetcherStateId;
            const networks = [
                {
                    chainId: this.dataFetcher.chainId,
                    baseToken: currency_1.WNATIVE[this.dataFetcher.chainId],
                    gasPrice: this.gasPrice,
                },
            ];
            let pools = this.dataFetcher.getCurrentPoolCodeList(this.providers).map(pc => pc.pool);
            if (this.poolFilter)
                pools = pools.filter(this.poolFilter);
            const route = (0, MultiRouter_1.findMultiRouteExactIn)(TokenToBaseToken(this.fromToken), TokenToBaseToken(this.toToken), this.amountIn, pools, networks, this.gasPrice);
            if (route.status !== amm_1.RouteStatus.NoWay) {
                this.currentBestRoute = route;
                if (this.routeCallBack)
                    this.routeCallBack(route);
            }
        }
    }
    static findBestRoute(dataFetcher, fromToken, amountIn, toToken, gasPrice, providers, // all providers if undefined
    poolFilter) {
        const networks = [
            {
                chainId: dataFetcher.chainId,
                baseToken: currency_1.WNATIVE[dataFetcher.chainId],
                gasPrice: gasPrice,
            },
        ];
        let pools = dataFetcher.getCurrentPoolCodeList(providers).map(pc => pc.pool);
        if (poolFilter)
            pools = pools.filter(poolFilter);
        return (0, MultiRouter_1.findMultiRouteExactIn)(TokenToBaseToken(fromToken), TokenToBaseToken(toToken), amountIn, pools, networks, gasPrice);
    }
    static routeProcessorParams(dataFetcher, route, fromToken, toToken, to, routeProcessorAddress, feeSettlementAddress, maxPriceImpact = 0.01) {
        const tokenIn = fromToken instanceof currency_1.Token
            ? fromToken.address
            : exports.NATIVE_ADDRESS;
        const tokenOut = toToken instanceof currency_1.Token
            ? toToken.address
            : exports.NATIVE_ADDRESS;
        const amountOutMin = route.amountOutBN
            .mul((0, util_1.getBigNumber)((1 - maxPriceImpact) * 1000000))
            .div(1000000);
        return {
            tokenIn,
            amountIn: route.amountInBN.toString(),
            tokenOut,
            amountOutMin: amountOutMin.toString(),
            to,
            routeCode: (0, RouteProcessor_1.getRouteProcessorCode)(route, routeProcessorAddress, feeSettlementAddress, dataFetcher.getCurrentPoolCodeMap()),
            value: fromToken instanceof currency_1.Token ? undefined : route.amountInBN.toString(),
        };
    }
    static routeProcessorParams2(dataFetcher, route, fromToken, toToken, to, routeProcessorAddress, feeSettlementAddress, maxPriceImpact = 0.01) {
        const tokenIn = fromToken instanceof currency_1.Token
            ? fromToken.address
            : exports.NATIVE_ADDRESS;
        const tokenOut = toToken instanceof currency_1.Token
            ? toToken.address
            : exports.NATIVE_ADDRESS;
        const amountOutMin = route.amountOutBN
            .mul((0, util_1.getBigNumber)((1 - maxPriceImpact) * 1000000))
            .div(1000000);
        return {
            tokenIn,
            amountIn: route.amountInBN.toString(),
            tokenOut,
            amountOutMin: amountOutMin.toString(),
            to,
            routeCode: (0, RouteProcessor2_1.getRouteProcessor2Code)(route, routeProcessorAddress, feeSettlementAddress, dataFetcher.getCurrentPoolCodeMap()),
            value: fromToken instanceof currency_1.Token ? undefined : route.amountInBN.toString(),
        };
    }
    static aggregationRouterParams(dataFetcher, route, fromToken, toToken, to, aggregationExecutorAddress, feeSettlementAddress, maxPriceImpact = 0.01) {
        const tokenIn = fromToken instanceof currency_1.Token
            ? fromToken.address
            : exports.NATIVE_ADDRESS;
        const tokenOut = toToken instanceof currency_1.Token
            ? toToken.address
            : exports.NATIVE_ADDRESS;
        const amountOutMin = route.amountOutBN
            .mul((0, util_1.getBigNumber)((1 - maxPriceImpact) * 1000000))
            .div(1000000);
        return {
            tokenIn,
            amountIn: route.amountInBN.toString(),
            tokenOut,
            amountOutMin: amountOutMin.toString(),
            to,
            routeCode: (0, AggregationRouter_1.getAggregationRouterCode)(route, aggregationExecutorAddress, feeSettlementAddress, dataFetcher.getCurrentPoolCodeMap()),
            value: fromToken instanceof currency_1.Token ? undefined : route.amountInBN.toString(),
        };
    }
    static routeToHumanString(dataFetcher, route, fromToken, toToken, shiftPrimary = '', shiftSub = '    ') {
        const poolCodesMap = dataFetcher.getCurrentPoolCodeMap();
        let res = '';
        res += `${shiftPrimary}Route Status: ${route.status}\n`;
        res += `${shiftPrimary}Input: ${route.amountIn / 10 ** fromToken.decimals} ${fromToken.symbol}\n`;
        route.legs.forEach((l, i) => {
            res
                += `${shiftSub}${i + 1}. ${l.tokenFrom.symbol} ${Math.round(l.absolutePortion * 100)}%`
                    + ` -> [${poolCodesMap.get(l.poolId)?.poolName}] -> ${l.tokenTo.symbol}\n`;
        });
        const output = Number.parseInt(route.amountOutBN.toString()) / 10 ** toToken.decimals;
        res += `${shiftPrimary}Output: ${output} ${route.toToken.symbol}`;
        return res;
    }
}
exports.Router = Router;
