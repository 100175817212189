import { getContract } from 'wagmi/actions'
import { otherChains } from '@zenlink-interface/wagmi-config'
import type { Address } from 'wagmi'
import { usePublicClient } from 'wagmi'
import { mainnet } from 'wagmi/chains'

const chains = [mainnet, ...otherChains]

export function getMulticall3ContractConfig(chainId: number | undefined) {
  return {
    address: (chains.find(chain => chain.id === chainId)?.contracts?.multicall3?.address || '') as Address,
    abi: [
      {
        inputs: [
          {
            components: [
              { internalType: 'address', name: 'target', type: 'address' },
              { internalType: 'bytes', name: 'callData', type: 'bytes' },
            ],
            internalType: 'struct Multicall3.Call[]',
            name: 'calls',
            type: 'tuple[]',
          },
        ],
        name: 'aggregate',
        outputs: [
          { internalType: 'uint256', name: 'blockNumber', type: 'uint256' },
          { internalType: 'bytes[]', name: 'returnData', type: 'bytes[]' },
        ],
        stateMutability: 'payable',
        type: 'function',
      },
      {
        inputs: [
          {
            components: [
              { internalType: 'address', name: 'target', type: 'address' },
              { internalType: 'bool', name: 'allowFailure', type: 'bool' },
              { internalType: 'bytes', name: 'callData', type: 'bytes' },
            ],
            internalType: 'struct Multicall3.Call3[]',
            name: 'calls',
            type: 'tuple[]',
          },
        ],
        name: 'aggregate3',
        outputs: [
          {
            components: [
              { internalType: 'bool', name: 'success', type: 'bool' },
              { internalType: 'bytes', name: 'returnData', type: 'bytes' },
            ],
            internalType: 'struct Multicall3.Result[]',
            name: 'returnData',
            type: 'tuple[]',
          },
        ],
        stateMutability: 'payable',
        type: 'function',
      },
      {
        inputs: [
          {
            components: [
              { internalType: 'address', name: 'target', type: 'address' },
              { internalType: 'bool', name: 'allowFailure', type: 'bool' },
              { internalType: 'uint256', name: 'value', type: 'uint256' },
              { internalType: 'bytes', name: 'callData', type: 'bytes' },
            ],
            internalType: 'struct Multicall3.Call3Value[]',
            name: 'calls',
            type: 'tuple[]',
          },
        ],
        name: 'aggregate3Value',
        outputs: [
          {
            components: [
              { internalType: 'bool', name: 'success', type: 'bool' },
              { internalType: 'bytes', name: 'returnData', type: 'bytes' },
            ],
            internalType: 'struct Multicall3.Result[]',
            name: 'returnData',
            type: 'tuple[]',
          },
        ],
        stateMutability: 'payable',
        type: 'function',
      },
      {
        inputs: [
          {
            components: [
              { internalType: 'address', name: 'target', type: 'address' },
              { internalType: 'bytes', name: 'callData', type: 'bytes' },
            ],
            internalType: 'struct Multicall3.Call[]',
            name: 'calls',
            type: 'tuple[]',
          },
        ],
        name: 'blockAndAggregate',
        outputs: [
          { internalType: 'uint256', name: 'blockNumber', type: 'uint256' },
          { internalType: 'bytes32', name: 'blockHash', type: 'bytes32' },
          {
            components: [
              { internalType: 'bool', name: 'success', type: 'bool' },
              { internalType: 'bytes', name: 'returnData', type: 'bytes' },
            ],
            internalType: 'struct Multicall3.Result[]',
            name: 'returnData',
            type: 'tuple[]',
          },
        ],
        stateMutability: 'payable',
        type: 'function',
      },
      {
        inputs: [],
        name: 'getBasefee',
        outputs: [{ internalType: 'uint256', name: 'basefee', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [{ internalType: 'uint256', name: 'blockNumber', type: 'uint256' }],
        name: 'getBlockHash',
        outputs: [{ internalType: 'bytes32', name: 'blockHash', type: 'bytes32' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'getBlockNumber',
        outputs: [{ internalType: 'uint256', name: 'blockNumber', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'getChainId',
        outputs: [{ internalType: 'uint256', name: 'chainid', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'getCurrentBlockCoinbase',
        outputs: [{ internalType: 'address', name: 'coinbase', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'getCurrentBlockDifficulty',
        outputs: [{ internalType: 'uint256', name: 'difficulty', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'getCurrentBlockGasLimit',
        outputs: [{ internalType: 'uint256', name: 'gaslimit', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'getCurrentBlockTimestamp',
        outputs: [{ internalType: 'uint256', name: 'timestamp', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [{ internalType: 'address', name: 'addr', type: 'address' }],
        name: 'getEthBalance',
        outputs: [{ internalType: 'uint256', name: 'balance', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'getLastBlockHash',
        outputs: [{ internalType: 'bytes32', name: 'blockHash', type: 'bytes32' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'bool', name: 'requireSuccess', type: 'bool' },
          {
            components: [
              { internalType: 'address', name: 'target', type: 'address' },
              { internalType: 'bytes', name: 'callData', type: 'bytes' },
            ],
            internalType: 'struct Multicall3.Call[]',
            name: 'calls',
            type: 'tuple[]',
          },
        ],
        name: 'tryAggregate',
        outputs: [
          {
            components: [
              { internalType: 'bool', name: 'success', type: 'bool' },
              { internalType: 'bytes', name: 'returnData', type: 'bytes' },
            ],
            internalType: 'struct Multicall3.Result[]',
            name: 'returnData',
            type: 'tuple[]',
          },
        ],
        stateMutability: 'payable',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'bool', name: 'requireSuccess', type: 'bool' },
          {
            components: [
              { internalType: 'address', name: 'target', type: 'address' },
              { internalType: 'bytes', name: 'callData', type: 'bytes' },
            ],
            internalType: 'struct Multicall3.Call[]',
            name: 'calls',
            type: 'tuple[]',
          },
        ],
        name: 'tryBlockAndAggregate',
        outputs: [
          { internalType: 'uint256', name: 'blockNumber', type: 'uint256' },
          { internalType: 'bytes32', name: 'blockHash', type: 'bytes32' },
          {
            components: [
              { internalType: 'bool', name: 'success', type: 'bool' },
              { internalType: 'bytes', name: 'returnData', type: 'bytes' },
            ],
            internalType: 'struct Multicall3.Result[]',
            name: 'returnData',
            type: 'tuple[]',
          },
        ],
        stateMutability: 'payable',
        type: 'function',
      },
    ],
  }
}

export function useMulticall3Contract(chainId: number) {
  return getContract({
    ...getMulticall3ContractConfig(chainId),
    walletClient: usePublicClient({ chainId }),
  })
}
