"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEBUG_MODE_ON = exports.DEBUG = exports.ASSERT = void 0;
function ASSERT(f, t) {
    if (process.env.NODE_ENV !== 'production') {
        if (!f() && t)
            console.error(t);
    }
}
exports.ASSERT = ASSERT;
let DEBUG_MODE = false;
function DEBUG(f) {
    if (DEBUG_MODE)
        f();
}
exports.DEBUG = DEBUG;
function DEBUG_MODE_ON(on) {
    DEBUG_MODE = on;
}
exports.DEBUG_MODE_ON = DEBUG_MODE_ON;
