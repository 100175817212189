"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StellaSwapV3Provider = void 0;
const chain_1 = require("@zenlink-interface/chain");
const LiquidityProvider_1 = require("./LiquidityProvider");
const AlgebraBase_1 = require("./AlgebraBase");
class StellaSwapV3Provider extends AlgebraBase_1.AlgebraBaseProvider {
    constructor(chainId, client) {
        const factory = {
            [chain_1.ParachainId.MOONBEAM]: '0xabE1655110112D0E45EF91e94f8d757e4ddBA59C',
        };
        const stateMultiCall = {
            [chain_1.ParachainId.MOONBEAM]: '0xed4777785e3021f61b391C01c56361e790fd8b19',
        };
        super(chainId, client, factory, stateMultiCall);
    }
    getType() {
        return LiquidityProvider_1.LiquidityProviders.StellaSwapV3;
    }
    getPoolProviderName() {
        return 'StellaSwapV3';
    }
}
exports.StellaSwapV3Provider = StellaSwapV3Provider;
