"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAmountX = exports.getAmountY = void 0;
const SwapMath_1 = require("./SwapMath");
const LogPowMath_1 = require("./LogPowMath");
function getAmountY(liquidity, sqrtPriceLX96, sqrtPriceRX96, sqrtRateX96) {
    const numerator = sqrtPriceRX96.sub(sqrtPriceLX96);
    const denominator = sqrtRateX96.sub(SwapMath_1.two96);
    return liquidity.mul(numerator).div(denominator);
}
exports.getAmountY = getAmountY;
function getAmountX(liquidity, leftPt, rightPt, sqrtPriceRX96, sqrtRateX96) {
    const sqrtPricePrPlX96 = (0, LogPowMath_1.getSqrtPrice)(rightPt - leftPt);
    const sqrtPricePrM1X96 = sqrtPriceRX96.mul(SwapMath_1.two96).div(sqrtRateX96);
    const numerator = sqrtPricePrPlX96.sub(SwapMath_1.two96);
    const denominator = sqrtPriceRX96.sub(sqrtPricePrM1X96);
    return liquidity.mul(numerator).div(denominator);
}
exports.getAmountX = getAmountX;
