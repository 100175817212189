"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.computePairAddress = void 0;
const address_1 = require("@ethersproject/address");
const solidity_1 = require("@ethersproject/solidity");
const constants_1 = require("./constants");
function computePairAddress({ factoryAddress, tokenA, tokenB, }) {
    const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]; // does safety checks
    return (0, address_1.getCreate2Address)(factoryAddress, (0, solidity_1.keccak256)(['bytes'], [(0, solidity_1.pack)(['address', 'address'], [token0.address, token1.address])]), constants_1.INIT_CODE_HASH[token0.chainId]);
}
exports.computePairAddress = computePairAddress;
