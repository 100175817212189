"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findRight = exports.findLeft = exports.isRight = exports.isLeft = exports.FindRightOperator = exports.FindLeftOperator = void 0;
var FindLeftOperator;
(function (FindLeftOperator) {
    FindLeftOperator[FindLeftOperator["LESS_THAN"] = 0] = "LESS_THAN";
    FindLeftOperator[FindLeftOperator["LESS_THAN_OR_EQUAL"] = 1] = "LESS_THAN_OR_EQUAL";
})(FindLeftOperator || (exports.FindLeftOperator = FindLeftOperator = {}));
var FindRightOperator;
(function (FindRightOperator) {
    FindRightOperator[FindRightOperator["GREATER_THAN"] = 0] = "GREATER_THAN";
    FindRightOperator[FindRightOperator["GREATER_THAN_OR_EQUAL"] = 1] = "GREATER_THAN_OR_EQUAL";
})(FindRightOperator || (exports.FindRightOperator = FindRightOperator = {}));
function isLeft(target, input, opt) {
    if (opt === FindLeftOperator.LESS_THAN)
        return target < input;
    return target <= input;
}
exports.isLeft = isLeft;
function isRight(target, input, opt) {
    if (opt === FindRightOperator.GREATER_THAN)
        return target > input;
    return target >= input;
}
exports.isRight = isRight;
function findLeft(array, input, opt) {
    if (!isLeft(array[0], input, opt))
        return -1;
    if (isLeft(array[array.length - 1], input, opt))
        return array.length - 1;
    let l = 0;
    let r = array.length - 1;
    while (l + 1 < r) {
        const mid = Math.floor((l + r) / 2);
        if (isLeft(array[mid], input, opt))
            l = mid;
        else
            r = mid;
    }
    return l;
}
exports.findLeft = findLeft;
function findRight(array, input, opt) {
    if (!isRight(array[array.length - 1], input, opt))
        return array.length;
    if (isRight(array[0], input, opt))
        return 0;
    let l = 0;
    let r = array.length - 1;
    while (l + 1 < r) {
        const mid = Math.floor((l + r) / 2);
        if (isRight(array[mid], input, opt))
            r = mid;
        else
            l = mid;
    }
    return l;
}
exports.findRight = findRight;
