"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasePool = exports.setTokenId = exports.TYPICAL_MINIMAL_LIQUIDITY = exports.TYPICAL_SWAP_GAS_COST = void 0;
exports.TYPICAL_SWAP_GAS_COST = 60000;
exports.TYPICAL_MINIMAL_LIQUIDITY = 1000;
function setTokenId(...tokens) {
    tokens.forEach((t) => {
        if (!t.tokenId)
            t.tokenId = `${t.address}-${t.chainId}`;
    });
}
exports.setTokenId = setTokenId;
class BasePool {
    constructor(address, token0, token1, fee, reserve0, reserve1, minLiquidity = exports.TYPICAL_MINIMAL_LIQUIDITY, swapGasCost = exports.TYPICAL_SWAP_GAS_COST) {
        this.address = address;
        this.token0 = token0;
        this.token1 = token1;
        setTokenId(this.token0, this.token1);
        this.poolId = `${address}-${this.token0.tokenId}-${this.token1.tokenId}`;
        this.fee = fee;
        this.minLiquidity = minLiquidity;
        this.swapGasCost = swapGasCost;
        this.reserve0 = reserve0;
        this.reserve1 = reserve1;
    }
    updateReserves(reserve0, reserve1) {
        this.reserve0 = reserve0;
        this.reserve1 = reserve1;
    }
    getReserve0() {
        return this.reserve0;
    }
    getReserve1() {
        return this.reserve1;
    }
    granularity0() {
        return 1;
    }
    granularity1() {
        return 1;
    }
    alwaysAppropriateForPricing() {
        return false;
    }
    cleanEffects() { }
}
exports.BasePool = BasePool;
