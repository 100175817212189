"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ZyberSwapV3Provider = void 0;
const chain_1 = require("@zenlink-interface/chain");
const LiquidityProvider_1 = require("./LiquidityProvider");
const AlgebraBase_1 = require("./AlgebraBase");
class ZyberSwapV3Provider extends AlgebraBase_1.AlgebraBaseProvider {
    constructor(chainId, client) {
        const factory = {
            [chain_1.ParachainId.ARBITRUM_ONE]: '0x9C2ABD632771b433E5E7507BcaA41cA3b25D8544',
        };
        const stateMultiCall = {
            [chain_1.ParachainId.ARBITRUM_ONE]: '0x41ed2463Fad6B7521F1FA651E5A1Eb47F41b626E',
        };
        super(chainId, client, factory, stateMultiCall);
    }
    getType() {
        return LiquidityProvider_1.LiquidityProviders.ZyberswapV3;
    }
    getPoolProviderName() {
        return 'ZyberswapV3';
    }
}
exports.ZyberSwapV3Provider = ZyberSwapV3Provider;
