"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoeV2Pool = void 0;
const util_1 = require("../../util");
const BasePool_1 = require("./BasePool");
const BASE_GAS_CONSUMPTION = 60000;
const STEP_GAS_CONSUMPTION = 20000;
function getSortedBins(activeId, bins) {
    const activeBinIndex = (bins.length - 1) / 2;
    const resBins = [bins[activeBinIndex]];
    for (let i = activeBinIndex - 1; i > 0; i--) {
        const bin = bins[i];
        if (bin.id < activeId)
            break;
        resBins.unshift(bin);
    }
    for (let i = activeBinIndex + 1; i < bins.length - 1; i++) {
        const bin = bins[i];
        if (bin.id > activeId)
            break;
        resBins.push(bin);
    }
    return resBins;
}
class JoeV2Pool extends BasePool_1.BasePool {
    constructor(address, token0, token1, fee, reserve0, reserve1, totalFee, binStep, activeId, bins) {
        super(address, token0, token1, fee, reserve0, reserve1);
        this.binsMap = new Map();
        this.binStep = binStep;
        this.activeId = activeId;
        this.totalFee = totalFee;
        this.bins = getSortedBins(activeId, bins);
        this.bins.forEach((bin, index) => this.binsMap.set(bin.id, { ...bin, index }));
    }
    updateState(reserve0, reserve1, activeId, bins) {
        this.updateReserves(reserve0, reserve1);
        this.activeId = activeId;
        this.bins = getSortedBins(activeId, bins);
        this.binsMap.clear();
        this.bins.forEach((bin, index) => this.binsMap.set(bin.id, { ...bin, index }));
    }
    _getPriceFromId(id) {
        return (1 + this.binStep / 10000) ** (id - 8388608);
    }
    _getFeeAmount(amount) {
        const denominator = 1e18 - this.totalFee;
        return (amount * this.totalFee + denominator - 1) / denominator;
    }
    _getFeeAmountFrom(amountWithFees) {
        return (amountWithFees * this.totalFee + 1e18 - 1) / 1e18;
    }
    getOutput(amountIn, direction) {
        let amountInLeft = amountIn;
        let id = this.activeId;
        let outAmount = 0;
        if (this.reserve0.eq(0) || this.reserve1.eq(0))
            return { output: 0, gasSpent: this.swapGasCost };
        let stepCounter = 0;
        while (true) {
            const bin = this.binsMap.get(id);
            if (!bin)
                break;
            const reserve = direction ? bin.reserve1 : bin.reserve0;
            if (!reserve.eq(0)) {
                const price = this._getPriceFromId(id);
                const reserveOut = direction ? (0, util_1.getNumber)(bin.reserve1) : (0, util_1.getNumber)(bin.reserve0);
                let maxAmountIn = direction ? reserveOut / price : reserveOut * price;
                const maxFee = this._getFeeAmount(maxAmountIn);
                maxAmountIn += maxFee;
                let amountInWithFees = amountInLeft;
                let fee;
                let amountOutOfBin;
                if (amountInLeft >= maxAmountIn) {
                    fee = maxFee;
                    amountInWithFees = maxAmountIn;
                    amountOutOfBin = reserveOut;
                }
                else {
                    fee = this._getFeeAmountFrom(amountInWithFees);
                    amountOutOfBin = direction ? (amountInWithFees - fee) * price : (amountInWithFees - fee) / price;
                    if (amountOutOfBin > reserveOut)
                        amountOutOfBin = reserveOut;
                }
                if (amountInWithFees > 0) {
                    amountInLeft -= amountInWithFees;
                    outAmount += amountOutOfBin;
                }
            }
            ++stepCounter;
            if (amountInLeft === 0) {
                break;
            }
            else {
                const binIndex = bin.index;
                const nextBinIndex = direction ? binIndex + 1 : binIndex - 1;
                if (nextBinIndex < 0 || nextBinIndex > this.bins.length - 1)
                    break;
                id = this.bins[nextBinIndex].id;
            }
        }
        return { output: outAmount, gasSpent: BASE_GAS_CONSUMPTION + STEP_GAS_CONSUMPTION * stepCounter };
    }
    getInput(amountOut, direction) {
        let amountOutLeft = amountOut;
        let id = this.activeId;
        let inAmount = 0;
        if (amountOut >= (direction ? (0, util_1.getNumber)(this.reserve1) : (0, util_1.getNumber)(this.reserve0)))
            return { input: Number.POSITIVE_INFINITY, gasSpent: this.swapGasCost };
        let stepCounter = 0;
        while (true) {
            const bin = this.binsMap.get(id);
            if (!bin)
                break;
            const reserve = direction ? (0, util_1.getNumber)(bin.reserve1) : (0, util_1.getNumber)(bin.reserve0);
            if (reserve > 0) {
                const price = this._getPriceFromId(id);
                const amountOutOfBin = reserve > amountOutLeft ? amountOutLeft : reserve;
                const amountInWithoutFee = direction ? amountOutOfBin / price : amountOutOfBin * price;
                const fee = this._getFeeAmount(amountInWithoutFee);
                inAmount += amountInWithoutFee + fee;
                amountOutLeft -= amountOutOfBin;
            }
            ++stepCounter;
            if (amountOutLeft === 0) {
                break;
            }
            else {
                const binIndex = bin.index;
                const nextBinIndex = direction ? binIndex + 1 : binIndex - 1;
                if (nextBinIndex < 0 || nextBinIndex > this.bins.length - 1)
                    break;
                id = this.bins[nextBinIndex].id;
            }
        }
        return { input: inAmount, gasSpent: BASE_GAS_CONSUMPTION + STEP_GAS_CONSUMPTION * stepCounter };
    }
    calcCurrentPriceWithoutFee(direction) {
        const currentPrice = this._getPriceFromId(this.activeId);
        return direction ? currentPrice : 1 / currentPrice;
    }
}
exports.JoeV2Pool = JoeV2Pool;
