// src/index.ts
import { ParachainId } from "@zenlink-interface/chain";
import { ARB, DAI, DOT, FRAX, KSM, Native, Token, USDC, USDT, WBTC, WNATIVE } from "@zenlink-interface/currency";
var BASES_TO_CHECK_TRADES_AGAINST = {
  [ParachainId.ASTAR]: [
    WNATIVE[ParachainId.ASTAR],
    USDC[ParachainId.ASTAR],
    DAI[ParachainId.ASTAR],
    USDT[ParachainId.ASTAR],
    DOT[ParachainId.ASTAR],
    new Token({
      chainId: ParachainId.ASTAR,
      address: "0x3795C36e7D12A8c252A20C5a7B455f7c57b60283",
      decimals: 6,
      symbol: "ceUSDT",
      name: "Tether USD (Celer)"
    }),
    new Token({
      chainId: ParachainId.ASTAR,
      address: "0x4Bf769b05E832FCdc9053fFFBC78Ca889aCb5E1E",
      decimals: 18,
      symbol: "BUSD",
      name: "Binance USD"
    }),
    new Token({
      chainId: ParachainId.ASTAR,
      address: "0x733ebcC6DF85f8266349DEFD0980f8Ced9B45f35",
      decimals: 18,
      symbol: "BAI",
      name: "BAI Stablecoin"
    }),
    new Token({
      chainId: ParachainId.ASTAR,
      address: "0xb6df5bafdcdce7aeb49af6172143e1942999ef14",
      decimals: 18,
      symbol: "4SRS",
      name: "4SRS"
    }),
    new Token({
      chainId: ParachainId.ASTAR,
      address: "0xe511ed88575c57767bafb72bfd10775413e3f2b0",
      decimals: 18,
      symbol: "nASTR",
      name: "Astar Note"
    })
  ],
  [ParachainId.ARBITRUM_ONE]: [
    WNATIVE[ParachainId.ARBITRUM_ONE],
    USDC[ParachainId.ARBITRUM_ONE],
    USDT[ParachainId.ARBITRUM_ONE],
    ARB[ParachainId.ARBITRUM_ONE],
    new Token({
      chainId: ParachainId.ARBITRUM_ONE,
      address: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
      decimals: 6,
      name: "USD Coin (Arb1)",
      symbol: "USDC.e"
    })
  ],
  [ParachainId.MOONBEAM]: [
    WNATIVE[ParachainId.MOONBEAM],
    FRAX[ParachainId.MOONBEAM],
    USDC[ParachainId.MOONBEAM],
    USDT[ParachainId.MOONBEAM],
    DOT[ParachainId.MOONBEAM],
    new Token({
      chainId: ParachainId.MOONBEAM,
      address: "0xc806B0600cbAfA0B197562a9F7e3B9856866E9bF",
      decimals: 18,
      name: "Deuterium",
      symbol: "d2O"
    }),
    new Token({
      chainId: ParachainId.MOONBEAM,
      address: "0xCa01a1D0993565291051daFF390892518ACfAD3A",
      decimals: 6,
      name: "Axelar Wrapped USDC",
      symbol: "axlUSDC"
    }),
    new Token({
      chainId: ParachainId.MOONBEAM,
      address: "0xFFffFfFF8cdA1707bAF23834d211B08726B1E499",
      decimals: 9,
      name: "xcEQD",
      symbol: "xcEQD"
    })
  ],
  [ParachainId.SCROLL_ALPHA]: [
    WNATIVE[ParachainId.SCROLL_ALPHA],
    USDC[ParachainId.SCROLL_ALPHA],
    USDT[ParachainId.SCROLL_ALPHA]
  ],
  [ParachainId.SCROLL]: [
    WNATIVE[ParachainId.SCROLL],
    USDC[ParachainId.SCROLL],
    USDT[ParachainId.SCROLL],
    DAI[ParachainId.SCROLL]
  ],
  [ParachainId.BASE]: [
    WNATIVE[ParachainId.BASE],
    USDC[ParachainId.BASE],
    DAI[ParachainId.BASE],
    new Token({
      chainId: ParachainId.BASE,
      address: "0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA",
      decimals: 6,
      symbol: "USDbc",
      name: "USD Base Coin"
    }),
    new Token({
      chainId: ParachainId.BASE,
      address: "0xEB466342C4d449BC9f53A865D5Cb90586f405215",
      decimals: 6,
      name: "Axelar Wrapped USDC",
      symbol: "axlUSDC"
    }),
    new Token({
      chainId: ParachainId.BASE,
      address: "0x417Ac0e078398C154EdFadD9Ef675d30Be60Af93",
      decimals: 18,
      name: "Curve.Fi USD Stablecoin",
      symbol: "crvUSD"
    })
  ],
  [ParachainId.AMPLITUDE]: [
    WNATIVE[ParachainId.AMPLITUDE],
    KSM[ParachainId.AMPLITUDE]
  ]
};
var ADDITIONAL_BASES = {};
var COMMON_BASES = {
  [ParachainId.ASTAR]: [
    Native.onChain(ParachainId.ASTAR),
    WNATIVE[ParachainId.ASTAR],
    USDC[ParachainId.ASTAR],
    USDT[ParachainId.ASTAR],
    DOT[ParachainId.ASTAR],
    new Token({
      chainId: ParachainId.ASTAR,
      address: "0xe511ed88575c57767bafb72bfd10775413e3f2b0",
      decimals: 18,
      symbol: "nASTR",
      name: "Astar Note"
    })
  ],
  [ParachainId.ARBITRUM_ONE]: [
    Native.onChain(ParachainId.ARBITRUM_ONE),
    ARB[ParachainId.ARBITRUM_ONE],
    USDC[ParachainId.ARBITRUM_ONE],
    USDT[ParachainId.ARBITRUM_ONE],
    DAI[ParachainId.ARBITRUM_ONE],
    WNATIVE[ParachainId.ARBITRUM_ONE],
    WBTC[ParachainId.ARBITRUM_ONE]
  ],
  [ParachainId.MOONBEAM]: [
    Native.onChain(ParachainId.MOONBEAM),
    new Token(
      {
        address: "0x6021D2C27B6FBd6e7608D1F39B41398CAee2F824",
        chainId: ParachainId.MOONBEAM,
        decimals: 18,
        name: "Cypress",
        symbol: "CP"
      }
    ),
    // USDC[ParachainId.MOONBEAM],
    USDT[ParachainId.MOONBEAM],
    new Token({
      chainId: ParachainId.MOONBEAM,
      address: "0xFFfffffF7D2B0B761Af01Ca8e25242976ac0aD7D",
      decimals: 6,
      symbol: "USDC",
      name: "USD Coin (Xcm)"
    })
    // DOT[ParachainId.MOONBEAM],
    // WNATIVE[ParachainId.MOONBEAM],
  ],
  [ParachainId.SCROLL_ALPHA]: [
    Native.onChain(ParachainId.SCROLL_ALPHA),
    USDC[ParachainId.SCROLL_ALPHA],
    USDT[ParachainId.SCROLL_ALPHA],
    DAI[ParachainId.SCROLL_ALPHA],
    WNATIVE[ParachainId.SCROLL_ALPHA]
  ],
  [ParachainId.SCROLL]: [
    Native.onChain(ParachainId.SCROLL),
    USDC[ParachainId.SCROLL],
    USDT[ParachainId.SCROLL],
    DAI[ParachainId.SCROLL],
    WNATIVE[ParachainId.SCROLL]
  ],
  [ParachainId.BASE]: [
    Native.onChain(ParachainId.BASE),
    USDC[ParachainId.BASE],
    new Token({
      chainId: ParachainId.BASE,
      address: "0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA",
      decimals: 6,
      symbol: "USDbc",
      name: "USD Base Coin"
    }),
    new Token({
      chainId: ParachainId.BASE,
      address: "0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb",
      decimals: 18,
      symbol: "DAI",
      name: "Dai Stablecoin"
    }),
    new Token({
      chainId: ParachainId.BASE,
      address: "0x2Ae3F1Ec7F1F5012CFEab0185bfc7aa3cf0DEc22",
      decimals: 18,
      symbol: "cbETH",
      name: "Coinbase Wrapped Staked ETH"
    }),
    WNATIVE[ParachainId.BASE]
  ],
  [ParachainId.AMPLITUDE]: [
    Native.onChain(ParachainId.AMPLITUDE),
    KSM[ParachainId.AMPLITUDE]
  ]
};
export {
  ADDITIONAL_BASES,
  BASES_TO_CHECK_TRADES_AGAINST,
  COMMON_BASES
};
