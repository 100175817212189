"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlgebraBaseProvider = void 0;
const chain_1 = require("@zenlink-interface/chain");
const bignumber_1 = require("@ethersproject/bignumber");
const router_config_1 = require("@zenlink-interface/router-config");
const entities_1 = require("../entities");
const abis_1 = require("../abis");
const util_1 = require("../util");
const LiquidityProvider_1 = require("./LiquidityProvider");
class AlgebraBaseProvider extends LiquidityProvider_1.LiquidityProvider {
    constructor(chainId, client, factory, stateMultiCall) {
        super(chainId, client);
        this.BIT_AMOUNT = 0;
        this.poolCodes = [];
        this.initialPools = new Map();
        this.factory = {};
        this.stateMultiCall = {};
        this.factory = factory;
        this.stateMultiCall = stateMultiCall;
    }
    async getPools(tokens) {
        if (!(this.chainId in this.factory) || !(this.chainId in this.stateMultiCall)) {
            this.lastUpdateBlock = -1;
            return;
        }
        // tokens deduplication
        const tokenMap = new Map();
        tokens.forEach(t => tokenMap.set((0, util_1.formatAddress)(t.address), t));
        const tokensDedup = Array.from(tokenMap.values());
        // tokens sorting
        const tok0 = tokensDedup.map(t => [(0, util_1.formatAddress)(t.address), t]);
        tokens = tok0.sort((a, b) => (b[0] > a[0] ? -1 : 1)).map(([_, t]) => t);
        const pools = [];
        for (let i = 0; i < tokens.length; ++i) {
            const t0 = tokens[i];
            for (let j = i + 1; j < tokens.length; ++j) {
                const t1 = tokens[j];
                pools.push({ token0: t0, token1: t1 });
            }
        }
        const poolState = await this.client
            .multicall({
            allowFailure: true,
            contracts: pools.map(pool => ({
                args: [
                    this.factory[this.chainId],
                    pool.token0.address,
                    pool.token1.address,
                    this.BIT_AMOUNT,
                    this.BIT_AMOUNT,
                ],
                address: this.stateMultiCall[this.chainId],
                chainId: chain_1.chainsParachainIdToChainId[this.chainId],
                abi: abis_1.algebraStateMulticall,
                functionName: 'getFullStateWithRelativeBitmaps',
            })),
        });
        const ticksMap = new Map();
        poolState.forEach((state) => {
            if (state.status !== 'success' || !state.result)
                return;
            const address = state.result?.pool;
            const tickBitmap = state.result?.tickBitmap;
            if (!address || !tickBitmap)
                return;
            const tickMap = ticksMap.get(address) || [];
            tickMap.concat(tickBitmap);
            ticksMap.set(address, tickMap);
        });
        pools.forEach((pool, i) => {
            if (poolState?.[i].status !== 'success' || !poolState?.[i].result)
                return;
            const address = poolState[i].result?.pool;
            const balance0 = poolState[i].result?.balance0;
            const balance1 = poolState[i].result?.balance1;
            const tick = poolState[i].result?.slot0.tick;
            const liquidity = poolState[i].result?.liquidity;
            const sqrtPriceX96 = poolState[i].result?.slot0.sqrtPriceX96;
            const tickBitmap = ticksMap.get(address || '');
            const fee = poolState[i].result?.slot0.fee;
            if (!address
                || !tick
                || !fee
                || !liquidity
                || !sqrtPriceX96
                || (!balance0 || bignumber_1.BigNumber.from(balance0).eq(0))
                || (!balance1 || bignumber_1.BigNumber.from(balance1).eq(0))
                || !tickBitmap)
                return;
            const ticks = Array.from(tickBitmap)
                .sort((a, b) => a.index - b.index)
                .map(tick => ({ index: tick.index, DLiquidity: bignumber_1.BigNumber.from(tick.value) }));
            const v3pool = new entities_1.UniV3Pool(address, pool.token0, pool.token1, fee / 1000000, bignumber_1.BigNumber.from(balance0), bignumber_1.BigNumber.from(balance1), tick, bignumber_1.BigNumber.from(liquidity), bignumber_1.BigNumber.from(sqrtPriceX96), ticks);
            const pc = new entities_1.AlgebraPoolCode(v3pool, this.getPoolProviderName());
            this.initialPools.set(address, pool);
            this.poolCodes.push(pc);
            ++this.stateId;
        });
    }
    _getProspectiveTokens(t0, t1) {
        const set = new Set([
            t0,
            t1,
            ...router_config_1.BASES_TO_CHECK_TRADES_AGAINST[this.chainId],
            ...(router_config_1.ADDITIONAL_BASES[this.chainId]?.[t0.address] || []),
            ...(router_config_1.ADDITIONAL_BASES[this.chainId]?.[t1.address] || []),
        ]);
        return Array.from(set);
    }
    startFetchPoolsData() {
        this.stopFetchPoolsData();
        this.poolCodes = [];
        this.getPools(router_config_1.BASES_TO_CHECK_TRADES_AGAINST[this.chainId]); // starting the process
        this.unwatchBlockNumber = this.client.watchBlockNumber({
            onBlockNumber: (blockNumber) => {
                this.lastUpdateBlock = Number(blockNumber);
            },
            onError: (error) => {
                console.error(error.message);
            },
        });
    }
    async fetchPoolsForToken(t0, t1) {
        await this.getPools(this._getProspectiveTokens(t0, t1));
    }
    getCurrentPoolList() {
        return this.poolCodes;
    }
    stopFetchPoolsData() {
        if (this.unwatchBlockNumber)
            this.unwatchBlockNumber();
    }
}
exports.AlgebraBaseProvider = AlgebraBaseProvider;
