"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UniswapV2BaseProvider = void 0;
const chain_1 = require("@zenlink-interface/chain");
const utils_1 = require("ethers/lib/utils");
const solidity_1 = require("@ethersproject/solidity");
const router_config_1 = require("@zenlink-interface/router-config");
const ethers_1 = require("ethers");
const entities_1 = require("../entities");
const util_1 = require("../util");
const LiquidityProvider_1 = require("./LiquidityProvider");
const getReservesAbi = [
    {
        inputs: [],
        name: 'getReserves',
        outputs: [
            {
                internalType: 'uint112',
                name: '_reserve0',
                type: 'uint112',
            },
            {
                internalType: 'uint112',
                name: '_reserve1',
                type: 'uint112',
            },
            {
                internalType: 'uint32',
                name: '_blockTimestampLast',
                type: 'uint32',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
];
class UniswapV2BaseProvider extends LiquidityProvider_1.LiquidityProvider {
    constructor(chainId, client) {
        super(chainId, client);
        this.fetchedPools = new Map();
        this.poolCodes = [];
        this.fee = 0.003;
    }
    async getPools(tokens) {
        if (!(this.chainId in this.factory)) {
            this.lastUpdateBlock = -1;
            return;
        }
        // tokens deduplication
        const tokenMap = new Map();
        tokens.forEach(t => tokenMap.set((0, util_1.formatAddress)(t.address), t));
        const tokensDedup = Array.from(tokenMap.values());
        // tokens sorting
        const tok0 = tokensDedup.map(t => [(0, util_1.formatAddress)(t.address), t]);
        tokens = tok0.sort((a, b) => (b[0] > a[0] ? -1 : 1)).map(([_, t]) => t);
        const poolAddr = new Map();
        for (let i = 0; i < tokens.length; ++i) {
            const t0 = tokens[i];
            for (let j = i + 1; j < tokens.length; ++j) {
                const t1 = tokens[j];
                const addr = this._getPoolAddress(t0, t1);
                poolAddr.set(addr, [t0, t1]);
                if (this.fetchedPools.get(addr) === undefined)
                    this.fetchedPools.set(addr, 1);
            }
        }
        const addrs = Array.from(poolAddr.keys());
        const results = await this.client
            .multicall({
            multicallAddress: this.client.chain?.contracts?.multicall3?.address,
            allowFailure: true,
            contracts: addrs.map(addr => ({
                address: addr,
                chainId: chain_1.chainsParachainIdToChainId[this.chainId],
                abi: getReservesAbi,
                functionName: 'getReserves',
            })),
        }).catch((e) => {
            console.warn(`${e.message}`);
            return undefined;
        });
        addrs.forEach((addr, i) => {
            const res0 = results?.[i]?.result?.[0];
            const res1 = results?.[i]?.result?.[1];
            if (res0 && res1) {
                const toks = poolAddr.get(addr);
                const pool = new entities_1.StandardPool(addr, toks[0], toks[1], this.fee, ethers_1.BigNumber.from(res0), ethers_1.BigNumber.from(res1));
                const pc = new entities_1.StandardPoolCode(pool, this.getPoolProviderName());
                this.poolCodes.push(pc);
                ++this.stateId;
            }
        });
    }
    async updatePoolsData() {
        if (!this.poolCodes.length)
            return;
        const poolAddr = new Map();
        this.poolCodes.forEach(p => poolAddr.set(p.pool.address, p.pool));
        const addrs = this.poolCodes.map(p => p.pool.address);
        const results = await this.client
            .multicall({
            multicallAddress: this.client.chain?.contracts?.multicall3?.address,
            allowFailure: true,
            contracts: addrs.map(addr => ({
                address: addr,
                chainId: chain_1.chainsParachainIdToChainId[this.chainId],
                abi: getReservesAbi,
                functionName: 'getReserves',
            })),
        }).catch((e) => {
            console.warn(`${e.message}`);
            return undefined;
        });
        addrs.forEach((addr, i) => {
            const res0 = results?.[i]?.result?.[0];
            const res1 = results?.[i]?.result?.[1];
            if (res0 && res1) {
                const res0BN = ethers_1.BigNumber.from(res0);
                const res1BN = ethers_1.BigNumber.from(res1);
                const pool = poolAddr.get(addr);
                if (!res0BN.eq(pool.reserve0) || !res1BN.eq(pool.reserve1)) {
                    pool.updateReserves(res0BN, res1BN);
                    ++this.stateId;
                }
            }
        });
    }
    _getPoolAddress(t1, t2) {
        return (0, utils_1.getCreate2Address)(this.factory[this.chainId], (0, solidity_1.keccak256)(['bytes'], [(0, solidity_1.pack)(['address', 'address'], [t1.address, t2.address])]), this.initCodeHash[this.chainId]);
    }
    _getProspectiveTokens(t0, t1) {
        const set = new Set([
            t0,
            t1,
            ...router_config_1.BASES_TO_CHECK_TRADES_AGAINST[this.chainId],
            ...(router_config_1.ADDITIONAL_BASES[this.chainId]?.[t0.address] || []),
            ...(router_config_1.ADDITIONAL_BASES[this.chainId]?.[t1.address] || []),
        ]);
        return Array.from(set);
    }
    startFetchPoolsData() {
        this.stopFetchPoolsData();
        this.poolCodes = [];
        this.fetchedPools.clear();
        this.getPools(router_config_1.BASES_TO_CHECK_TRADES_AGAINST[this.chainId]); // starting the process
        this.unwatchBlockNumber = this.client.watchBlockNumber({
            onBlockNumber: (blockNumber) => {
                this.lastUpdateBlock = Number(blockNumber);
                this.updatePoolsData();
            },
            onError: (error) => {
                console.error(error.message);
            },
        });
    }
    async fetchPoolsForToken(t0, t1) {
        await this.getPools(this._getProspectiveTokens(t0, t1));
    }
    getCurrentPoolList() {
        return this.poolCodes;
    }
    stopFetchPoolsData() {
        if (this.unwatchBlockNumber)
            this.unwatchBlockNumber();
    }
}
exports.UniswapV2BaseProvider = UniswapV2BaseProvider;
