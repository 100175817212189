/** @type {import('next-seo').DefaultSeoProps} */
export default {
  defaultTitle: 'CP Trading',
  title: 'CP Trading',
  titleTemplate: '%s',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@ZenlinkPro',
    site: '@ZenlinkPro',
  },
  additionalLinkTags: [
    {
      rel: 'icon',
      href: 'swap/favicon.ico',
    },
  ],
}
