"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findMultiRouteExactIn = void 0;
const bignumber_1 = require("@ethersproject/bignumber");
const amm_1 = require("@zenlink-interface/amm");
const entities_1 = require("../entities");
function isSpecialPool(pool) {
    return pool instanceof entities_1.StablePool || pool instanceof entities_1.MetaPool;
}
function deduplicatePools(pools) {
    const poolMap = new Map();
    pools.forEach((p) => {
        const chId0 = p.token0.chainId || 0;
        const chId1 = p.token1.chainId || 0;
        const chainInfo = chId0 < chId1 ? `_${chId0}_${chId1}` : `_${chId1}_${chId0}`;
        poolMap.set(p.poolId + chainInfo, p);
    });
    return Array.from(poolMap.values());
}
function breakupSepcialPools(pools) {
    const speicalPools = pools.filter(isSpecialPool);
    if (!speicalPools.length)
        return [pools];
    const normalPools = pools.filter(pool => !isSpecialPool(pool));
    const specialPoolsSet = new Set();
    function isRelated(p0, p1) {
        return (p0.address === p1.address
            || p0.address === p1.baseSwap?.contractAddress
            || p1.address === p0.baseSwap?.contractAddress
            || p0.baseSwap?.contractAddress === p1.baseSwap?.contractAddress);
    }
    speicalPools.forEach((p) => {
        const notRelatedPools = [];
        speicalPools.forEach((sp) => {
            if (!isRelated(p, sp) && notRelatedPools.every(nrp => !isRelated(nrp, sp)))
                notRelatedPools.push(sp);
        });
        specialPoolsSet.add([...normalPools, ...notRelatedPools, p]);
    });
    return Array.from(specialPoolsSet);
}
function checkChainId(pools, baseTokenOrNetworks) {
    if (Array.isArray(baseTokenOrNetworks)) {
        baseTokenOrNetworks.forEach((n) => {
            if (n.chainId !== n.baseToken.chainId)
                throw new Error(`Chain '${n.chainId}' has baseToken with '${n.baseToken.chainId}' that are not the same`);
        });
    }
    const chainIds = Array.isArray(baseTokenOrNetworks) ? baseTokenOrNetworks.map(n => n.chainId) : [baseTokenOrNetworks.chainId];
    const chainIdSet = new Set(chainIds);
    const checkToken = (t) => {
        if (!chainIdSet.has(t.chainId)) {
            throw new Error(`Token ${t.name}/${t.address} chainId='${t.chainId}' is not in list of possible chains: [${chainIds.join(', ')}]`);
        }
    };
    pools.forEach((p) => {
        checkToken(p.token0);
        checkToken(p.token1);
    });
}
function calcPriceImactWithoutFee(route) {
    if (route.primaryPrice === undefined || route.swapPrice === undefined) {
        return undefined;
    }
    else {
        let oneMinusCombinedFee = 1;
        route.legs.forEach(l => (oneMinusCombinedFee *= 1 - l.poolFee));
        return Math.max(0, 1 - route.swapPrice / route.primaryPrice / oneMinusCombinedFee);
    }
}
const DEFAULT_FLOW_NUMBER = 12;
const MAX_FLOW_NUMBER = 50;
function calcBestFlowNumber(bestSingleRoute, amountIn, gasPriceIn) {
    if (amountIn instanceof bignumber_1.BigNumber)
        amountIn = Number.parseInt(amountIn.toString());
    const priceImpact = calcPriceImactWithoutFee(bestSingleRoute);
    if (!priceImpact)
        return DEFAULT_FLOW_NUMBER;
    const bestFlowAmount = Math.sqrt((bestSingleRoute.gasSpent * (gasPriceIn || 0) * amountIn) / priceImpact);
    const bestFlowNumber = Math.round(amountIn / bestFlowAmount);
    if (!Number.isFinite(bestFlowNumber))
        return MAX_FLOW_NUMBER;
    const realFlowNumber = Math.max(1, Math.min(bestFlowNumber, MAX_FLOW_NUMBER));
    return realFlowNumber;
}
function getBetterRouteExactIn(route1, route2) {
    if (route1.status === amm_1.RouteStatus.NoWay)
        return 1;
    if (route2.status === amm_1.RouteStatus.NoWay)
        return -1;
    if (route1.status === amm_1.RouteStatus.Partial && route2.status === amm_1.RouteStatus.Success)
        return 1;
    if (route2.status === amm_1.RouteStatus.Partial && route1.status === amm_1.RouteStatus.Success)
        return -1;
    return route1.totalAmountOut > route2.totalAmountOut ? -1 : 1;
}
function sortRoutes(routes) {
    return routes.sort(getBetterRouteExactIn);
}
function findMultiRouteExactIn(from, to, amountIn, pools, baseTokenOrNetworks, gasPrice, flows) {
    pools = deduplicatePools(pools);
    checkChainId(pools, baseTokenOrNetworks);
    (0, entities_1.setTokenId)(from, to);
    const poolsAfterBreakup = breakupSepcialPools(pools);
    const routes = [];
    poolsAfterBreakup.forEach((pools) => {
        const g = new entities_1.Graph(pools, from, baseTokenOrNetworks, gasPrice);
        if (flows !== undefined) {
            routes.push(g.findBestRouteExactIn(from, to, amountIn, flows));
        }
        else {
            const outSingle = g.findBestRouteExactIn(from, to, amountIn, 1);
            g.cleanCache();
            const bestFlowNumber = calcBestFlowNumber(outSingle, amountIn, g.getVert(from)?.gasPrice);
            if (bestFlowNumber === 1) {
                routes.push(outSingle);
            }
            else {
                const outMulti = g.findBestRouteExactIn(from, to, amountIn, bestFlowNumber);
                routes.push(sortRoutes([outSingle, outMulti])[0]);
            }
        }
    });
    return sortRoutes(routes)[0];
}
exports.findMultiRouteExactIn = findMultiRouteExactIn;
