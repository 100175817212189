"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DodoV2Provider = void 0;
const currency_1 = require("@zenlink-interface/currency");
const chain_1 = require("@zenlink-interface/chain");
const bignumber_1 = require("@ethersproject/bignumber");
const entities_1 = require("../entities");
const abis_1 = require("../abis");
const util_1 = require("../util");
const LiquidityProvider_1 = require("./LiquidityProvider");
class DodoV2Provider extends LiquidityProvider_1.LiquidityProvider {
    constructor(chainId, client) {
        super(chainId, client);
        this.poolCodes = [];
        this.stateMultiCall = {
            [chain_1.ParachainId.SCROLL_ALPHA]: '0xc093DAf4ECFce49Cc1d726bED4343A7416dc5A51',
        };
        this.DVMfactory = {
            [chain_1.ParachainId.SCROLL_ALPHA]: '0x0b96b88B1941C3Df77b5A065dD3075fDB7986301',
        };
        this.tokenGroupMap = {
            [chain_1.ParachainId.SCROLL_ALPHA]: [
                [
                    currency_1.WNATIVE[chain_1.ParachainId.SCROLL_ALPHA],
                    new currency_1.Token({
                        chainId: chain_1.ParachainId.SCROLL_ALPHA,
                        address: '0xDf40f3a3566b4271450083f1Ad5732590BA47575',
                        symbol: 'EURS',
                        name: 'EURS',
                        decimals: 2,
                    }),
                ],
            ],
        };
    }
    async getPools(tokenGroup) {
        if (!(this.chainId in this.stateMultiCall)) {
            this.lastUpdateBlock = -1;
            return;
        }
        const DVMPoolStates = this.chainId in this.DVMfactory
            ? await this.client
                .multicall({
                allowFailure: true,
                contracts: tokenGroup.map(([token0, token1]) => ({
                    args: [
                        this.DVMfactory[this.chainId],
                        token0.address,
                        token1.address,
                    ],
                    address: this.stateMultiCall[this.chainId],
                    chainId: chain_1.chainsParachainIdToChainId[this.chainId],
                    abi: abis_1.dodoV2StateMulticall,
                    functionName: 'getFullState',
                })),
            })
            : undefined;
        tokenGroup.forEach((group, i) => {
            const poolState = DVMPoolStates?.[i];
            if (poolState?.status !== 'success' || !poolState.result)
                return;
            poolState.result.forEach((res) => {
                const reserve0 = bignumber_1.BigNumber.from(res.reserve0);
                const reserve1 = bignumber_1.BigNumber.from(res.reserve1);
                if (reserve0.eq(0) || reserve1.eq(0))
                    return;
                const [token0, token1] = group[0].address.toLowerCase() === res.token0
                    ? [group[0], group[1]]
                    : [group[1], group[0]];
                const dodov2pool = new entities_1.DodoV2Pool(res.pool, token0, token1, (0, util_1.getNumber)(res.lpFeeRate) / 1e18, bignumber_1.BigNumber.from(res.reserve0), bignumber_1.BigNumber.from(res.reserve1), {
                    i: (0, util_1.getNumber)(res.state.i),
                    K: (0, util_1.getNumber)(res.state.K),
                    B: (0, util_1.getNumber)(res.state.B),
                    Q: (0, util_1.getNumber)(res.state.Q),
                    B0: (0, util_1.getNumber)(res.state.B0),
                    Q0: (0, util_1.getNumber)(res.state.Q0),
                    R: res.state.R,
                }, bignumber_1.BigNumber.from(res.lpFeeRate), bignumber_1.BigNumber.from(res.mtFeeRate), bignumber_1.BigNumber.from(res.midPrice));
                const pc = new entities_1.DodoV2PoolCode(dodov2pool, this.getPoolProviderName());
                this.poolCodes.push(pc);
                ++this.stateId;
            });
        });
    }
    _getProspectiveTokens(t0, t1) {
        if (!(this.chainId in this.tokenGroupMap))
            return [];
        const tokenGroup = this.tokenGroupMap[this.chainId];
        return tokenGroup.filter(group => group[0].address.toLowerCase() === t0.address.toLowerCase()
            || group[0].address.toLowerCase() === t1.address.toLowerCase()
            || group[1].address.toLowerCase() === t0.address.toLowerCase()
            || group[1].address.toLowerCase() === t1.address.toLowerCase());
    }
    startFetchPoolsData() {
        this.stopFetchPoolsData();
        this.poolCodes = [];
        if (!(this.chainId in this.tokenGroupMap))
            return;
        this.getPools(this.tokenGroupMap[this.chainId]); // starting the process
        this.unwatchBlockNumber = this.client.watchBlockNumber({
            onBlockNumber: (blockNumber) => {
                this.lastUpdateBlock = Number(blockNumber);
            },
            onError: (error) => {
                console.error(error.message);
            },
        });
    }
    async fetchPoolsForToken(t0, t1) {
        await this.getPools(this._getProspectiveTokens(t0, t1));
    }
    getCurrentPoolList() {
        return this.poolCodes;
    }
    stopFetchPoolsData() {
        if (this.unwatchBlockNumber)
            this.unwatchBlockNumber();
    }
    getType() {
        return LiquidityProvider_1.LiquidityProviders.DODOV2;
    }
    getPoolProviderName() {
        return 'DODOV2';
    }
}
exports.DodoV2Provider = DodoV2Provider;
