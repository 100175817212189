"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertStableSwapOrPairToPool = void 0;
const StablePool_1 = require("../StablePool");
function convertStableSwapOrPairToPool(pairs, swaps) {
    const pools = [...pairs];
    for (const swap of swaps) {
        const relatedSwaps = swaps.filter(otherSwap => otherSwap.involvesToken(swap.liquidityToken));
        for (let i = 0; i < swap.pooledTokens.length; i++) {
            for (let j = i + 1; j < swap.pooledTokens.length; j++) {
                pools.push(new StablePool_1.StablePool(swap, swap.pooledTokens[i], swap.pooledTokens[j]));
            }
            if (!relatedSwaps.length)
                continue;
            for (const relatedSwap of relatedSwaps) {
                for (const token of relatedSwap.pooledTokens) {
                    if (token.equals(swap.liquidityToken))
                        continue;
                    pools.push(new StablePool_1.StablePool(swap, swap.pooledTokens[i], token, relatedSwap));
                }
            }
        }
    }
    return pools;
}
exports.convertStableSwapOrPairToPool = convertStableSwapOrPairToPool;
