"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyncswapProvider = void 0;
const chain_1 = require("@zenlink-interface/chain");
const bignumber_1 = require("@ethersproject/bignumber");
const router_config_1 = require("@zenlink-interface/router-config");
const entities_1 = require("../entities");
const util_1 = require("../util");
const abis_1 = require("../abis");
const LiquidityProvider_1 = require("./LiquidityProvider");
class SyncswapProvider extends LiquidityProvider_1.LiquidityProvider {
    constructor(chainId, client) {
        super(chainId, client);
        this.poolCodes = [];
        this.stateMultiCall = {
            [chain_1.ParachainId.SCROLL_ALPHA]: '0xfb39167FE3b148ADd082ca62FBE9413CF5Fa101f',
        };
        this.classicFactory = {
            [chain_1.ParachainId.SCROLL_ALPHA]: '0x46c8dc568ED604bB18C066Fc8d387859b7977836',
        };
        this.stableFactory = {
            [chain_1.ParachainId.SCROLL_ALPHA]: '0x441B24fc497317767a9D293931A33939953F251f',
        };
    }
    async getPools(tokens) {
        if (!(this.chainId in this.stateMultiCall)) {
            this.lastUpdateBlock = -1;
            return;
        }
        // tokens deduplication
        const tokenMap = new Map();
        tokens.forEach(t => tokenMap.set((0, util_1.formatAddress)(t.address), t));
        const tokensDedup = Array.from(tokenMap.values());
        // tokens sorting
        const tok0 = tokensDedup.map(t => [(0, util_1.formatAddress)(t.address), t]);
        tokens = tok0.sort((a, b) => (b[0] > a[0] ? -1 : 1)).map(([_, t]) => t);
        const pools = [];
        for (let i = 0; i < tokens.length; ++i) {
            const t0 = tokens[i];
            for (let j = i + 1; j < tokens.length; ++j) {
                const t1 = tokens[j];
                pools.push([t0, t1]);
            }
        }
        const poolState = await this.client
            .multicall({
            allowFailure: true,
            contracts: pools.map(pool => ({
                args: [
                    this.classicFactory[this.chainId],
                    this.stableFactory[this.chainId],
                    pool[0].address,
                    pool[1].address,
                ],
                address: this.stateMultiCall[this.chainId],
                chainId: chain_1.chainsParachainIdToChainId[this.chainId],
                abi: abis_1.syncswapStateMulticall,
                functionName: 'getFullState',
            })),
        })
            .catch((e) => {
            console.warn(e.message);
            return undefined;
        });
        poolState?.forEach((states, i) => {
            if (states.status !== 'success' || !states.result)
                return;
            states.result.forEach((state) => {
                const reserve0 = bignumber_1.BigNumber.from(state.reserve0);
                const reserve1 = bignumber_1.BigNumber.from(state.reserve1);
                const swapFee = state.swapFee / 1e5;
                const syncPool = new entities_1.SyncPool(state.pool, pools[i][0], pools[i][1], swapFee, reserve0, reserve1, state.isStable);
                const pc = new entities_1.SyncPoolCode(syncPool, this.getPoolProviderName());
                this.poolCodes.push(pc);
                ++this.stateId;
            });
        });
    }
    _getProspectiveTokens(t0, t1) {
        const set = new Set([
            t0,
            t1,
            ...router_config_1.BASES_TO_CHECK_TRADES_AGAINST[this.chainId],
            ...(router_config_1.ADDITIONAL_BASES[this.chainId]?.[t0.address] || []),
            ...(router_config_1.ADDITIONAL_BASES[this.chainId]?.[t1.address] || []),
        ]);
        return Array.from(set);
    }
    startFetchPoolsData() {
        this.stopFetchPoolsData();
        this.poolCodes = [];
        this.getPools(router_config_1.BASES_TO_CHECK_TRADES_AGAINST[this.chainId]); // starting the process
        this.unwatchBlockNumber = this.client.watchBlockNumber({
            onBlockNumber: (blockNumber) => {
                this.lastUpdateBlock = Number(blockNumber);
            },
            onError: (error) => {
                console.error(error.message);
            },
        });
    }
    async fetchPoolsForToken(t0, t1) {
        await this.getPools(this._getProspectiveTokens(t0, t1));
    }
    getCurrentPoolList() {
        return this.poolCodes;
    }
    stopFetchPoolsData() {
        if (this.unwatchBlockNumber)
            this.unwatchBlockNumber();
    }
    getType() {
        return LiquidityProvider_1.LiquidityProviders.Syncswap;
    }
    getPoolProviderName() {
        return 'Syncswap';
    }
}
exports.SyncswapProvider = SyncswapProvider;
