"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAggregationRouterCode = exports.AggregationRouter = void 0;
const amm_1 = require("@zenlink-interface/amm");
const entities_1 = require("../entities");
const HEXer_1 = require("../HEXer");
const Router_1 = require("./Router");
class AggregationRouter {
    constructor(aggregationExecutorAddress, feeSettlementAddress, chainId, pools) {
        this.aggregationExecutorAddress = aggregationExecutorAddress;
        this.feeSettlementAddress = feeSettlementAddress;
        this.chainId = chainId;
        this.pools = pools;
        this.tokenOutputLegs = new Map();
    }
    getRouteCode(route) {
        // 0. Check for no route
        if (route.status === amm_1.RouteStatus.NoWay || !route.legs.length)
            return '';
        this.calcTokenOutputLegs(route);
        let res = '0x';
        const processedTokens = new Set();
        route.legs.forEach((l, i) => {
            const token = l.tokenFrom;
            if (processedTokens.has(token.tokenId))
                return;
            processedTokens.add(token.tokenId);
            if (i > 0) {
                if (token.address === '')
                    throw new Error(`unexpected native inside the route: ${token.symbol}`);
                res += this.processERC20Code(token, route);
            }
            else {
                if (token.address === '')
                    res += this.processNativeCode(token, route);
                else
                    res += this.processERC20Code(token, route);
            }
        });
        return res;
    }
    processNativeCode(token, route) {
        const outputLegs = this.tokenOutputLegs.get(token.tokenId);
        if (!outputLegs || outputLegs.length !== 1)
            throw new Error(`Not 1 output pool for native token: ${outputLegs?.length}`);
        const hex = new HEXer_1.HEXer()
            .address(Router_1.NATIVE_ADDRESS)
            .uint8(outputLegs.length);
        outputLegs.forEach((l) => {
            hex.share16(l.swapPortion).uint8(1).hexData(this.swapCode(l, route));
        });
        return hex.toString();
    }
    processERC20Code(token, route) {
        const outputLegs = this.tokenOutputLegs.get(token.tokenId);
        if (!outputLegs || !outputLegs.length)
            throw new Error(`No output legs for token ${token.symbol}`);
        const hex = new HEXer_1.HEXer()
            .address(token.address)
            .uint8(outputLegs.length);
        outputLegs.forEach((l) => {
            hex.share16(l.swapPortion)
                .uint8(l.tokenTo.address === '' ? 1 : 2)
                .hexData(this.swapCode(l, route));
        });
        return hex.toString();
    }
    swapCode(leg, route) {
        const pc = this.getPoolCode(leg);
        const to = this.getPoolOutputAddress(leg, route);
        return pc.getSwapCodeForAggregationRouter(leg, route, to); // , this.presendedLegs.has(leg))
    }
    getPoolOutputAddress(l, route) {
        let outAddress;
        const outputDistribution = this.tokenOutputLegs.get(l.tokenTo.tokenId) || [];
        if (!outputDistribution.length) {
            outAddress = this.feeSettlementAddress;
        }
        else if (outputDistribution.length === 1) {
            outAddress = this.getPoolCode(outputDistribution[0]).getProtocolExecutorStartPoint(l, route);
            if (outAddress === entities_1.PoolCode.ProtocolExecutorAddress)
                outAddress = this.aggregationExecutorAddress;
        }
        else {
            outAddress = this.aggregationExecutorAddress;
        }
        return outAddress;
    }
    getPoolCode(l) {
        const pc = this.pools.get(l.poolId);
        if (pc === undefined)
            throw new Error(`unknown pool: ${l.poolId}`);
        return pc;
    }
    calcTokenOutputLegs(route) {
        const res = new Map();
        route.legs.forEach((l) => {
            const tokenId = l.tokenFrom.tokenId?.toString();
            if (tokenId === undefined) {
                throw new Error('Unseted tokenId');
            }
            else {
                const legsOutput = res.get(tokenId) || [];
                legsOutput.push(l);
                res.set(tokenId, legsOutput);
            }
        });
        this.tokenOutputLegs = res;
    }
}
exports.AggregationRouter = AggregationRouter;
function getAggregationRouterCode(route, aggregationExecutorAddress, feeSettlementAddress, pools) {
    const rp = new AggregationRouter(aggregationExecutorAddress, feeSettlementAddress, route.fromToken.chainId, pools);
    return rp.getRouteCode(route);
}
exports.getAggregationRouterCode = getAggregationRouterCode;
