"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NativeWrapProvider = void 0;
const currency_1 = require("@zenlink-interface/currency");
const entities_1 = require("../entities");
const LiquidityProvider_1 = require("./LiquidityProvider");
class NativeWrapProvider extends LiquidityProvider_1.LiquidityProvider {
    constructor(chainId, client) {
        super(chainId, client);
        const native = currency_1.Native.onChain(chainId);
        const nativeToken = {
            address: '',
            name: native.name,
            symbol: native.symbol,
            chainId,
        };
        const bridge = new entities_1.NatvieWrapPool(currency_1.WNATIVE_ADDRESS[chainId], nativeToken, currency_1.WNATIVE[chainId], 0, 50000);
        this.poolCodes = [new entities_1.NativeWrapPoolCode(bridge)];
        this.stateId = 0;
        this.lastUpdateBlock = -1;
    }
    getType() {
        return LiquidityProvider_1.LiquidityProviders.NativeWrap;
    }
    getPoolProviderName() {
        return 'NativeWrap';
    }
    startFetchPoolsData() { }
    async fetchPoolsForToken() { }
    getCurrentPoolList() {
        return this.poolCodes;
    }
    stopFetchPoolsData() { }
}
exports.NativeWrapProvider = NativeWrapProvider;
