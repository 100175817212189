import { Token } from '@zenlink-interface/currency'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ParachainId } from '@zenlink-interface/chain'
import type { StorageContext } from '../context'
import type { TokenAsObject, WithStorageState } from '../types'

type UseCustomTokensReturn = [
  Record<string, Token>,
  {
    addCustomToken(payload: TokenAsObject): void
    addCustomTokens(payload: TokenAsObject[]): void
    removeCustomToken(payload: Pick<TokenAsObject, 'address' | 'chainId'>): void
  },
]

type UseCustomTokens = (context: StorageContext, chainId?: number) => UseCustomTokensReturn

export const useCustomTokens: UseCustomTokens = (context, chainId?: number) => {
  const { reducerPath, actions } = context
  const { customTokens } = useSelector((state: WithStorageState) => state[reducerPath])
  const dispatch = useDispatch()

  const addCustomToken = useCallback(
    ({ symbol, address, chainId, name, decimals }: TokenAsObject) => {
      dispatch(actions.addCustomToken({ symbol, address, chainId, name, decimals }))
    },
    [actions, dispatch],
  )

  const addCustomTokens = useCallback(
    (tokens: TokenAsObject[]) => {
      dispatch(actions.addCustomTokens(tokens))
    },
    [actions, dispatch],
  )

  const removeCustomToken = useCallback(
    ({ address, chainId }: Pick<TokenAsObject, 'address' | 'chainId'>) => {
      dispatch(actions.removeCustomToken({ address, chainId }))
    },
    [actions, dispatch],
  )

  const tokens = useMemo(() => {
    const newCustomTokens = { ...customTokens }
    if (!chainId || chainId !== ParachainId.MOONBEAM)
      return {}

    newCustomTokens[ParachainId.MOONBEAM] = {
      '0x6021d2c27b6fbd6e7608d1f39b41398caee2f824': {
        address: '0x6021D2C27B6FBd6e7608D1F39B41398CAee2F824',
        chainId: 2004,
        decimals: 18,
        name: 'Cypress',
        symbol: 'CP',
      },
      '0xfFfFffFf43B4560Bc0C451a3386E082bff50aC90': {
        chainId: 2004,
        decimals: 10,
        symbol: 'SUB',
        name: 'Subsocial',
        address: '0xfFfFffFf43B4560Bc0C451a3386E082bff50aC90',
      },
      '0xFffFFFFF4C1cbCd97597339702436d4F18a375Ab': {
        chainId: 2004,
        decimals: 10,
        symbol: 'INTR',
        name: 'Interlay',
        address: '0xFffFFFFF4C1cbCd97597339702436d4F18a375Ab',
      },
      '0xFfFFfFff1FcaCBd218EDc0EbA20Fc2308C778080': {
        chainId: 2004,
        decimals: 10,
        symbol: 'DOT',
        name: 'DOT',
        address: '0xFfFFfFff1FcaCBd218EDc0EbA20Fc2308C778080',
      },
    }

    return Object.entries(newCustomTokens[chainId]).reduce<Record<string, Token>>(
      (acc, [k, { chainId, decimals, name, symbol, address }]) => {
        acc[k] = new Token({ chainId, decimals, name, symbol, address })
        return acc
      },
      {},
    )
  }, [chainId, customTokens])

  return [tokens, { addCustomToken, removeCustomToken, addCustomTokens }]
}
