"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateSlippageAmount = void 0;
const math_1 = require("@zenlink-interface/math");
const ONE = new math_1.Fraction(1, 1);
function calculateSlippageAmount(value, slippage) {
    if (slippage.lessThan(0) || slippage.greaterThan(ONE))
        throw new Error('Unexpected slippage');
    return [value.multiply(ONE.subtract(slippage)).quotient, value.multiply(ONE.add(slippage)).quotient];
}
exports.calculateSlippageAmount = calculateSlippageAmount;
