"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GmxPool = void 0;
const bignumber_1 = require("@ethersproject/bignumber");
const currency_1 = require("@zenlink-interface/currency");
const viem_1 = require("viem");
const util_1 = require("../../util");
const BasePool_1 = require("./BasePool");
function adjustForDecimals(amount, tokenDiv, tokenMul) {
    return amount * (10 ** tokenMul.decimals) / (10 ** tokenDiv.decimals);
}
const MinimalDiffUsdgAmount = bignumber_1.BigNumber.from(10000).mul(bignumber_1.BigNumber.from(10).pow(18));
class GmxPool extends BasePool_1.BasePool {
    constructor(address, token0, token1, fee, reserve0, reserve1, usdgAmount0, usdgAmount1, maxUsdgAmount0, maxUsdgAmount1, token0MaxPrice, token0MinPrice, token1MaxPrice, token1MinPrice, swapFee, stableSwapFee, taxFee, stableTaxFee) {
        super(address, token0, token1, fee, reserve0, reserve1, BasePool_1.TYPICAL_MINIMAL_LIQUIDITY, BasePool_1.TYPICAL_SWAP_GAS_COST);
        this.isStable = this.fee === stableSwapFee;
        this._USDG = new currency_1.Token({
            chainId: this.token0.chainId,
            address: viem_1.zeroAddress,
            name: 'USDG',
            symbol: 'USDG',
            decimals: 18,
        });
        this._token0 = token0;
        this._token1 = token1;
        this.usdgAmount0 = usdgAmount0;
        this.usdgAmount1 = usdgAmount1;
        this.maxUsdgAmount0 = maxUsdgAmount0;
        this.maxUsdgAmount1 = maxUsdgAmount1;
        this.token0MaxPrice = token0MaxPrice;
        this.token0MinPrice = token0MinPrice;
        this.token1MaxPrice = token1MaxPrice;
        this.token1MinPrice = token1MinPrice;
        this.swapFee = swapFee;
        this.stableSwapFee = stableSwapFee;
        this.taxFee = taxFee;
        this.stableTaxFee = stableTaxFee;
    }
    updateState(reserve0, reserve1, token0MaxPrice, token0MinPrice, token1MaxPrice, token1MinPrice) {
        this.updateReserves(reserve0, reserve1);
        this.token0MaxPrice = token0MaxPrice;
        this.token0MinPrice = token0MinPrice;
        this.token1MaxPrice = token1MaxPrice;
        this.token1MinPrice = token1MinPrice;
    }
    _checkDecreasePoolAmount(isToken0, amount) {
        const reserve = isToken0 ? this.reserve0 : this.reserve1;
        const price = isToken0 ? this.token0MinPrice : this.token1MinPrice;
        let usdgAmount = (0, util_1.getNumber)(reserve) * (0, util_1.getNumber)(price) / 10 ** 30;
        usdgAmount = adjustForDecimals(usdgAmount, isToken0 ? this._token0 : this._token1, this._USDG);
        if (usdgAmount < (0, util_1.getNumber)(MinimalDiffUsdgAmount))
            return false;
        if (reserve.lt(amount))
            return false;
        return true;
    }
    _checkIncreaseUsdgAmount(isToken0, amount) {
        const usdgAmount = isToken0 ? this.usdgAmount0 : this.usdgAmount1;
        const maxUsdgAmount = isToken0 ? this.maxUsdgAmount0 : this.maxUsdgAmount1;
        if (!maxUsdgAmount.eq(0)) {
            if (maxUsdgAmount.sub(usdgAmount).lt(MinimalDiffUsdgAmount))
                return false;
            if (usdgAmount.add(amount).gt(maxUsdgAmount))
                return false;
        }
        return true;
    }
    getOutput(amountIn, direction) {
        const priceIn = direction ? this.token0MinPrice : this.token1MinPrice;
        const priceOut = direction ? this.token1MaxPrice : this.token0MaxPrice;
        const amountOut = amountIn * (0, util_1.getNumber)(priceIn) / (0, util_1.getNumber)(priceOut);
        const amountOutAfterAdjustDecimals = adjustForDecimals(amountOut, direction ? this._token0 : this._token1, direction ? this._token1 : this._token0);
        const reserveOut = direction ? (0, util_1.getNumber)(this.reserve1) : (0, util_1.getNumber)(this.reserve0);
        let usdgAmount = amountIn * (0, util_1.getNumber)(priceIn) / 10 ** 30;
        usdgAmount = adjustForDecimals(usdgAmount, direction ? this._token0 : this._token1, this._USDG);
        if (!this._checkIncreaseUsdgAmount(direction, (0, util_1.getBigNumber)(usdgAmount))
            || !this._checkDecreasePoolAmount(!direction, (0, util_1.getBigNumber)(amountOutAfterAdjustDecimals)))
            return { output: 0, gasSpent: this.swapGasCost };
        const taxFee = amountOutAfterAdjustDecimals * (this.isStable ? this.stableTaxFee : this.taxFee) / reserveOut;
        return { output: amountOutAfterAdjustDecimals * (1 - this.fee - taxFee), gasSpent: this.swapGasCost };
    }
    getInput(amountOut, direction) {
        const priceIn = direction ? this.token0MinPrice : this.token1MinPrice;
        const priceOut = direction ? this.token1MaxPrice : this.token0MaxPrice;
        const reserveOut = direction ? (0, util_1.getNumber)(this.reserve1) : (0, util_1.getNumber)(this.reserve0);
        if (!this._checkDecreasePoolAmount(!direction, (0, util_1.getBigNumber)(amountOut)))
            return { input: Number.POSITIVE_INFINITY, gasSpent: this.swapGasCost };
        const taxFee = amountOut * (this.isStable ? this.stableTaxFee : this.taxFee) / reserveOut;
        const amountOutBeforeFee = amountOut / (1 - this.fee - taxFee);
        const amountIn = amountOutBeforeFee * (0, util_1.getNumber)(priceOut) / (0, util_1.getNumber)(priceIn);
        const amountInAfterAdjustDecimals = adjustForDecimals(amountIn, direction ? this._token1 : this._token0, direction ? this._token0 : this._token1);
        let usdgAmount = amountInAfterAdjustDecimals * (0, util_1.getNumber)(priceIn) / 10 ** 30;
        usdgAmount = adjustForDecimals(usdgAmount, direction ? this._token0 : this._token1, this._USDG);
        if (!this._checkIncreaseUsdgAmount(direction, (0, util_1.getBigNumber)(usdgAmount)))
            return { input: Number.POSITIVE_INFINITY, gasSpent: this.swapGasCost };
        return { input: amountInAfterAdjustDecimals, gasSpent: this.swapGasCost };
    }
    calcCurrentPriceWithoutFee(direction) {
        const priceIn = direction ? this.token0MinPrice : this.token1MinPrice;
        const priceOut = direction ? this.token1MaxPrice : this.token0MaxPrice;
        const price = adjustForDecimals((0, util_1.getNumber)(priceIn) / (0, util_1.getNumber)(priceOut), direction ? this._token0 : this._token1, direction ? this._token1 : this._token0);
        return price;
    }
}
exports.GmxPool = GmxPool;
