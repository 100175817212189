"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAggregationRouterAddressForChainId = exports.getAggregationExecutorAddressForChainId = exports.isAggregationRouter = exports.ENABLED_AGGREGATION_ROUTER_CHAINS = void 0;
const chain_1 = require("@zenlink-interface/chain");
exports.ENABLED_AGGREGATION_ROUTER_CHAINS = [
    chain_1.ParachainId.MOONBEAM,
    chain_1.ParachainId.SCROLL_ALPHA,
    chain_1.ParachainId.SCROLL,
    chain_1.ParachainId.BASE,
    chain_1.ParachainId.ASTAR,
];
function isAggregationRouter(chainId) {
    return Boolean(chainId && exports.ENABLED_AGGREGATION_ROUTER_CHAINS.includes(chainId));
}
exports.isAggregationRouter = isAggregationRouter;
function getAggregationExecutorAddressForChainId(chainId) {
    switch (chainId) {
        case chain_1.ParachainId.MOONBEAM:
            return '0x832B21FA3AA074Ee5328f653D9DB147Bcb155C7a';
        case chain_1.ParachainId.SCROLL_ALPHA:
            return '0xf6EA707CBf38f2Acf3bf029429B55192c61c67ad';
        case chain_1.ParachainId.SCROLL:
            return '0x4e231728d42565830157FFFaBBB9c78aD5152E94';
        case chain_1.ParachainId.BASE:
            return '0x4e231728d42565830157FFFaBBB9c78aD5152E94';
        case chain_1.ParachainId.ASTAR:
            return '0x934AF6d0C4b6EaF259AcEEf3225827C3025B29c5';
        default:
            throw new Error(`Unsupported route processor network for ${chainId}`);
    }
}
exports.getAggregationExecutorAddressForChainId = getAggregationExecutorAddressForChainId;
function getAggregationRouterAddressForChainId(chainId) {
    switch (chainId) {
        case chain_1.ParachainId.MOONBEAM:
            return '0x603eF396029b5e89f9420b4192814aEC0664ADAb';
        case chain_1.ParachainId.SCROLL_ALPHA:
            return '0xAd4f1E1Ba6bD15ab06E9eA4Dd0583b4693b669B1';
        case chain_1.ParachainId.SCROLL:
            return '0xf5016C2DF297457a1f9b036990cc704306264B40';
        case chain_1.ParachainId.BASE:
            return '0x7BAe21fB8408D534aDfeFcB46371c3576a1D5717';
        case chain_1.ParachainId.ASTAR:
            return '0x8f68eAA5DD8c43fdb9A236ed9C76DD6182D3060D';
        default:
            throw new Error(`Unsupported route processor network for ${chainId}`);
    }
}
exports.getAggregationRouterAddressForChainId = getAggregationRouterAddressForChainId;
